<template>
  <section id="login">
    <kdw-auth-component
      :sign-up-type="signUpType"
    />
  </section>
</template>

<script>
  import { Component, Prop, Vue } from 'vue-property-decorator'

  Component.registerHooks([
    'metaInfo',
  ])

  @Component({
    name: 'Login',
  })

  class Login extends Vue {
    metaInfo () {
      return {
        title: this.$t('login.title'),
        titleTemplate: (titleChunk) => {
          return titleChunk ? `${titleChunk} - ${this.$t('meta.titleTemplate')}` : this.$t('meta.titleTemplate')
        },
      }
    }

    // coming from router
    @Prop(String) signUpType
  }
  export default Login
</script>
